import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/index'),
		meta: {
			parent: '',
			showSearch: true,
		},
	},
	{
		path: '/old_index',
		name: 'old_index',
		component: () => import('@/views/old_index'),
	},
	//政策超市
	{
		path: '/zccs',
		name: 'zccs',
		component: () => import('@/views/zccs/index'),
		meta: {
			parent: 'zccs',
		},
	},
	/*
	{
		path: '/zccs/shenbao',
		name: 'shenbao',
		component: () => import('@/views/zccs/shenbao'),
	},
	{
		path: '/zccs/huiqi',
		name: 'huiqi',
		component: () => import('@/views/zccs/huiqi'),
	},
	{
		path: '/zccs/jiedu',
		name: 'jiedu',
		component: () => import('@/views/zccs/jiedu'),
	},
	{
		path: '/zccs/pipei',
		name: 'pipei',
		component: () => import('@/views/zccs/pipei'),
	},
	*/
	{
		path: '/cgzh',
		name: 'cgzh',
		component: () => import('@/views/cgzh/index'),
	},
	{
		path: '/cgzh/zhuanjia',
		name: 'zhuanjia',
		component: () => import('@/views/cgzh/zhuanjia'),
	},
	{
		path: '/cgzh/chengguo',
		name: 'chengguo',
		component: () => import('@/views/cgzh/chengguo'),
	},
	//服务超市相关
	{
		path: '/fwcs',
		name: 'fwcs',
		component: () => import('@/views/fwcs/index'),
		meta: {
			parent: 'fwcs',
			title: '服务超市',
		},
	},
	{
		path: '/fwcs/organization',
		name: 'fwcsOrganization',
		component: () => import('@/views/fwcs/organization'),
		meta: {
			parent: 'fwcs',
		},
	},
	{
		path: '/fwcs/organizationDetails',
		name: 'fwcsDetails',
		component: () => import('@/views/fwcs/organizationDetails'),
	},
	{
		path: '/fwcs/products',
		name: 'fwcsProducts',
		component: () => import('@/views/fwcs/products'),
		meta: {
			parent: 'fwcs',
		},
	},
	{
		path: '/fwcs/productsDetails',
		name: 'fwcsProductsDetails',
		component: () => import('@/views/fwcs/productsDetails'),
	},
	{
		path: '/fwcs/activities',
		name: 'fwcsActivities',
		component: () => import('@/views/fwcs/activities'),
	},
	{
		path: '/fwcs/activitiesDetails',
		name: 'fwcsActivitiesDetails',
		component: () => import('@/views/fwcs/activitiesDetails'),
	},
	//政策库
	{
		path: '/policy',
		name: 'policy',
		component: () => import('@/views/policy/index'),
		meta: {
			parent: 'policy',
			title: '政策超市',
		},
	},
	{
		path: '/policy/sdIndex',
		name: 'sdIndex',
		component: () => import('@/views/policy/sdIndex'),
		meta: {
			parent: 'policy',
			title: '山东省政策汇编',
		},
	},
	{
		path: '/policy/sdDetails',
		name: 'sdDetails',
		component: () => import('@/views/policy/sdDetails'),
		meta: {
			parent: 'policy',
			title: '政策详情',
		},
	},
	{
		path: '/policy/lkIndex',
		name: 'lkIndex',
		component: () => import('@/views/policy/lkIndex'),
		meta: {
			parent: 'policy',
			title: '龙口市政策汇编',
		},
	},
	{
		path: '/policy/lkDetails',
		name: 'lkDetails',
		component: () => import('@/views/policy/lkDetails'),
		meta: {
			parent: 'policy',
			title: '政策详情',
		},
	},
	//专家库
	{
		path: '/expert',
		name: 'expert',
		component: () => import('@/views/expert/index'),
		meta: {
			parent: 'expert',
			title: '专家咨询',
		},
	},
	{
		path: '/expert/expertIndex',
		name: 'expertIndex',
		component: () => import('@/views/expert/expertIndex'),
		meta: {
			parent: 'expert',
			title: '专家咨询',
		},
	},
	{
		path: '/expert/expertDetails',
		name: 'expertDetails',
		component: () => import('@/views/expert/expertDetails'),
		meta: {
			parent: 'expert',
			title: '专家咨询',
		},
	},
	{
		path: '/expert/angelIndex',
		name: 'angelIndex',
		component: () => import('@/views/expert/angelIndex'),
		meta: {
			parent: 'expert',
			title: '天使导师',
		},
	},
	{
		path: '/expert/angelDetails',
		name: 'angelDetails',
		component: () => import('@/views/expert/angelDetails'),
		meta: {
			parent: 'expert',
			title: '天使导师',
		},
	},
	{
		path: '/achievements',
		name: 'achievements',
		component: () => import('@/views/achievements/index'),
		meta: {
			parent: 'achievements',
			title: '成果转化',
		},
	},
	{
		path: '/achievements/gjIndex',
		name: 'achievementsgjIndex',
		component: () => import('@/views/achievements/gjIndex'),
		meta: {
			parent: 'achievements',
			title: '科技成果供给超市',
		},
	},
	{
		path: '/achievements/gjDetails',
		name: 'achievementsgjDetails',
		component: () => import('@/views/achievements/gjDetails'),
		meta: {
			parent: 'achievements',
			title: '科技成果供给 - 详情',
		},
	},
	{
		path: '/achievements/xqIndex',
		name: 'achievementsxqIndex',
		component: () => import('@/views/achievements/xqIndex'),
		meta: {
			parent: 'achievements',
			title: '科技成果需求超市',
		},
	},
	{
		path: '/achievements/xqDetails',
		name: 'achievementsxqDetails',
		component: () => import('@/views/achievements/xqDetails'),
		meta: {
			parent: 'achievements',
			title: '科技成果需求 - 详情',
		},
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('@/views/service/index'),
		meta: {
			parent: 'service',
			title: '服务超市',
		},
	},
	{
		path: '/service/list',
		name: 'serviceList',
		component: () => import('@/views/service/list'),
		meta: {
			parent: 'service',
			title: '服务超市列表',
		},
	},
	{
		path: '/service/details',
		name: 'serviceDetails',
		component: () => import('@/views/service/details'),
		meta: {
			parent: 'service',
			title: '服务机构详情',
		},
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news/index'),
		meta: {
			parent: 'news',
			title: '新闻动态',
		},
	},
	{
		path: '/news/nNewsIndex',
		name: 'nNewsIndex',
		component: () => import('@/views/news/nNewsIndex'),
		meta: {
			parent: 'news',
			title: '国内新闻',
		},
	},
	{
		path: '/news/wNewsIndex',
		name: 'wNewsIndex',
		component: () => import('@/views/news/wNewsIndex'),
		meta: {
			parent: 'news',
			title: '国际新闻',
		},
	},
	{
		path: '/news/wNewsDetails',
		name: 'wNewsDetails',
		component: () => import('@/views/news/wNewsDetails'),
		meta: {
			parent: 'news',
			title: '国际新闻详情',
		},
	},
	{
		path: '/news/nNewsDetails',
		name: 'nNewsDetails',
		component: () => import('@/views/news/nNewsDetails'),
		meta: {
			parent: 'news',
			title: '国内新闻详情',
		},
	},
	{
		path: '/demand',
		name: 'demand',
		component: () => import('@/views/demand/index'),
		meta: {
			parent: 'demand',
			title: '需求匹配',
		},
	},
	{
		path: '/demand/search',
		name: 'demandSearch',
		component: () => import('@/views/demand/search'),
		meta: {
			parent: 'demand',
			title: '需求匹配',
		},
	},
	{
		path: '/demand/feedback',
		name: 'demandFeedback',
		component: () => import('@/views/demand/feedback'),
		meta: {
			parent: 'demand',
			title: '诉求反馈',
		},
	},
	{
		path: '*',
		component: () => import('@/views/404.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;
