<template>
	<div id="app">
		<Header />
		<router-view />
		<Footer />
		<div class="backTop"></div>
	</div>
</template>

<script>
import Header from '@/components/index/Header';
import Footer from '@/components/common/Footer';

export default {
	components: { Header, Footer },

	props: {},
	data() {
		return {
			isSearch: true,
			page_index: '',
			subRoutes: [],
		};
	},
	watch: {},
	computed: {},
	methods: {},
	created() {
		this.$router.beforeEach((to, from, next) => {
			this.$emit('routeChanged', to, from);
			next();
		});
	},
	mounted() {},
};
</script>
<style lang="scss" scoped></style>
