export const Meun = {
	policy: {
		title: '政策超市',
		desc: '旨在帮助企业和个人获取相关的政策、法规、规章等信息。政策超市汇集了各个领域的政策文件。',

		path: '/policy/sdIndex',
		children: [
			{ title: '龙口市政策汇编', path: '/policy/lkIndex' },
			{ title: '山东省政策汇编', path: '/policy/sdIndex' },
		],
	},
	fwcs: {
		title: '服务超市',
		desc: '为企业提供了一个寻找创新技术和解决方案的渠道，帮助他们快速获取先进的科技成果。',
		// img: '/can-be-delete/user.jpg',
		path: '/fwcs',
		children: [
			{ title: '技术咨询', path: '/fwcs/organization' },
			{ title: '检测认证', path: '/fwcs/products' },
			{ title: '知识产权服务', path: '/fwcs/activities' },
			{ title: '融资对接', path: '/fwcs/activities' },
			{ title: '创业孵化', path: '/fwcs/activities' },
			{ title: '猎头服务', path: '/fwcs/activities' },
			{ title: '财税服务', path: '/fwcs/activities' },
		],
	},

	achievements: {
		title: '成果转化',
		desc: '为企业提供了一个寻找创新技术和解决方案的渠道，帮助他们快速获取先进的科技成果。',

		path: '/achievements/gjIndex',
		children: [
			{ title: '科技成果供给超市', path: '/achievements/gjIndex' },
			{ title: '科技成果需求超市', path: '/achievements/xqIndex' },
		],
	},
	expert: {
		title: '专家咨询',
		desc: '包括高校专家库、院所专家库和天使投资，为您提供多领域专家咨询服务。',

		path: '/expert/expertIndex',
		children: [
			{ title: '高校院所专家库', path: '/expert/expertIndex' },
			{ title: '天使导师', path: '/expert/angelIndex' },
		],
	},
	news: {
		title: '新闻动态',
		path: '/news/nNewsIndex',

		children: [
			{ title: '国内新闻', path: '/news/nNewsIndex' },
			{ title: '国际新闻', path: '/news/wNewsIndex' },
		],
	},
	demand: {
		title: '诉求平台',
		path: '/demand/search',
		children: [
			{ title: '技术、人才需求匹配', path: '/demand/search' },
			{ title: '意见建议', path: '/demand/feedback' },
		],
	},
};
