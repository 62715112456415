<template>
	<div class="Header-wrapper">
		<div class="contain-1400 clearFix">
			<router-link to="/" class="Header-logo">
				<img
					src="/static/images/logo-white.png"
					alt="首页"
					class="Header-logo-white wow zoomIn"
				/>
				<img
					src="/static/images/logo.png"
					alt="首页"
					class="Header-logo-blue"
				/>
			</router-link>
			<div class="Header-search" v-if="headerData.showSearch">
				<div class="Header-search-click"></div>
				<div class="Header-search-item">
					<div class="contain">
						<form action="#" method="get">
							<button type="submit" id="searchSubmit"></button>
							<input
								type="text"
								name="keyword"
								id="searchText"
								placeholder="搜索..."
								autocomplete="off"
							/>
						</form>
					</div>
				</div>
			</div>

			<div class="Header-navbar clearFix">
				<ul class="navbarScroll clearFix wow fadeInDown">
					<li
						:class="`${pageIndex == index ? 'active' : ''}`"
						v-for="(item, index) in Meun"
						:key="index"
					>
						<router-link :to="item.path" class="Header-menu">
							{{ item.title }}
						</router-link>
						<i class="Header-arrow"></i>
						<div class="Header-drop" v-if="item.children">
							<router-link
								:to="child.path"
								v-for="(child, childIndex) in item.children"
								:key="childIndex"
							>
								{{ child.title }}
							</router-link>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="Header-dropBg"></div>
	</div>
</template>

<script>
import { Meun } from '@/common/meun';
export default {
	components: {},
	props: {},
	data() {
		return {
			pageIndex: '',
			Meun,
			Path: 1,
			headerData: { showSearch: false },
		};
	},
	watch: {},
	computed: {},
	methods: {
		//获取父组件
		handleRouteChange(to) {
			to.meta.parent ? (this.pageIndex = to.meta.parent) : null;
		},
	},
	created() {
		this.$parent.$on('routeChanged', this.handleRouteChange);
	},
	mounted() {
		const currentPath = window.location.pathname;
		this.pageIndex = currentPath.split('/')[1];
	},

	destroyed() {
		this.$parent.$off('routeChanged', this.handleRouteChange);
	},
};
</script>
<style lang="scss">
@import url('@/assets/css/index.css');
</style>
