import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//title
const defaultTitle = '华东科创成果转化数据平台'; /*默认 title*/
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});

//element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//import '@/common/rem';

/* 引入aos动画库相关文件 */
// import AOS from 'aos';
// import 'aos/dist/aos.css';

/* aos动画初始化 */
// AOS.init({
// 	duration: 1000,
// 	easing: 'ease-in-out-back',
// });

// import ECHeaderindex from '@/components/ECHeaderindex';
// import ECFooterindex from '@/components/ECFooterindex';
// import ECHeaderInner from '@/components/ECHeaderInner';
// import ECBg from '@/components/ECBg';
// import ECTab from '@/components/ECTab';

// 全局注册组件
// Vue.component('ECHeaderindex', ECHeaderindex);
// Vue.component('ECFooterindex', ECFooterindex);
// Vue.component('ECHeaderInner', ECHeaderInner);
// Vue.component('ECBg', ECBg);
// Vue.component('ECTab', ECTab);

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.config.productionTip =
	process.env.NODE_ENV ===
	'development'; /* production生产环境  development开发环境 */

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
