<template>
	<div class="Footer-wrapper">
		<div class="Footer-items">
			<div class="clearFix" style="width: 100%; padding: 0 100px">
				<div class="Footer-info wow fadeInUp">
					<router-link :to="{ path: '/' }" ondragstart="return false">
						<img
							src="/static/picture/footer-logo-white.png"
							alt=""
							ondragstart="return false"
						/>
					</router-link>
				</div>
				<div class="Footer-navbar wow fadeInUp">
					<ul class="clearFix">
						<li v-for="(item, index) in Meun" :key="index">
							<div class="Footer-menu">{{ item.title }}</div>
							<div class="Footer-drop" v-if="item.children">
								<router-link
									:to="child.path"
									v-for="(child, childIndex) in item.children"
									:key="childIndex"
								>
									{{ child.title }}
								</router-link>
							</div>
						</li>
					</ul>
				</div>
				<div class="Footer-form wow fadeInUp">
					<h6>服务电话</h6>
					<span class="Footer-from-phone">0535-8888888</span>
					<div class="Footer-from-cont">
						<p>电子邮箱：TheSuperLiang@qq.com</p>
						<p>主办单位：华东科创成果转化数据平台</p>
						<p>单位地址：山东省烟台市龙口市东城区港城大道1000号A座8楼</p>
					</div>
				</div>
			</div>
		</div>
		<div class="Footer-copyright">
			<div class="clearFix" style="padding: 0px 100px">
				<div class="copy-cont">
					<h6>
						Copyright © 2024
						<a v-if="false" href="https://beian.miit.gov.cn/" target="_blank">
							鲁ICP备88975541号
						</a>
						华东科创成果转化数据平台 版权所有
					</h6>
					<i></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Meun } from '@/common/meun';

export default {
	components: {},
	props: {},
	data() {
		return { Meun };
	},
	watch: {},
	computed: {},
	methods: {},
	created() {},
	mounted() {},
};
</script>
